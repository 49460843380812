// "use strict";
import React from "react";
// import Swiper from '../common/swiper'
class Home extends React.Component {
    render () {
        return (
            <div>
                <div className="wf-bg"></div>
                <div className="wf-bg" style={{height: '628px',backgroundImage: `url('https://cdn.hfbeta.com/20220228/107874501033912569.jpg')`}}></div>
                <div className="wf-bg" style={{height: '847px',backgroundImage: `url('https://cdn.hfbeta.com/20220228/107874652576697864.png')`}}></div>
                <div className="wf-bg" style={{height: '346px',backgroundImage: `url('https://cdn.hfbeta.com/20220309/107926020669614354.png')`}}></div>
            </div>
        );
    }
}
export default Home;
