import { Routes, Route } from "react-router-dom";
import Footer from "./common/footer";
import Header from "./common/header";

import Home from "./page/home";
import About from "./page/about";
import Product from "./page/product";

const App = (props) => {
  return (
    <div className="App">
      <header className="wf-header-ab">
        <Header />
      </header>
      <main className="wf-col3">
          <Routes>
            <Route path="/" index element={<Home animate={true} />}></Route>
            <Route path="/product" element={<Product animate={true} />}></Route>
            <Route path="/about" element={<About animate={true} />}></Route>
          </Routes>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};
export default App;
