import React from 'react';
import { Link } from "react-router-dom";
import Json from '../json';

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    handleLink (index) {
        this.setState({
            currentIndex: index
        })
    }
    componentDidMount () {}
    render () {
        const { currentIndex } = this.state
        return (
            <div className="wf-header">
                <div className="wf-flex flex-align-center wf-pt56">
                    <div className="wf-flex-4">
                        <img className="img-logo" src="https://cdn.hfbeta.com/20220225/107856619864569357.gif"></img>
                    </div>
                    {!!Json.linkToList.length && Json.linkToList.map((item, index) => {
                        return <div key={index}
                            className={`wf-header-title wf-flex-3 ${index == currentIndex ? 'wf-header-title-active' : ''} ${index == 1 ? 'wf-tac' : ''}  ${index == 2 ? 'wf-tar' : ''} wf-active`}
                            onClick={this.handleLink.bind(this, item.index)}
                        >
                            <Link to={`/${item.to}`}>{item.name}</Link>
                        </div>
                    })}
                </div>
            </div>
        );
    }
}

export default Header;
