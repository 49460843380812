// "use strict";
import React from "react";

class Product extends React.Component {
    render () {
        return (
            <div>
                <div className="wf-bg" style={{height: '779px',backgroundImage: `url('https://cdn.hfbeta.com/20220228/107874683602922296.jpg')`}}></div>
                <div className="wf-bg" style={{height: '790px',backgroundImage: `url('https://cdn.hfbeta.com/20220228/107874683608580721.png')`}}></div>
                <div className="wf-bg" style={{height: '738px',backgroundImage: `url('https://cdn.hfbeta.com/20220228/107874686182643792.png')`}}></div>
                <div className="wf-bg" style={{height: '714px',backgroundImage: `url('https://cdn.hfbeta.com/20220228/107874686603323707.png')`}}></div>
            </div>
        );
    }
}
export default Product;

