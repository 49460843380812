import React from 'react';

class Footer extends React.Component {
    state = {  }
    render() {
        return (
            <div>
                <div className="wf-bg" style={{height: '283px',backgroundImage: `url('https://cdn.hfbeta.com/20220228/107874726444480983.png')`}}></div>
                <div className="wf-tac wf-pb30"><a href="https://beian.miit.gov.cn/" target="_blank">ICP备案：粤ICP备18130491号</a></div>
            </div>
        );
    }
}

export default Footer;