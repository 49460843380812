
import React from 'react';

class About extends React.Component {
    render () {
        return (
            <div>
                <div className="wf-bg" style={{height: '779px',backgroundImage: `url('https://cdn.hfbeta.com/20220228/107874725976101053.jpg')`}}></div>
                <div className="wf-bg" style={{height: '251px',backgroundImage: `url('https://cdn.hfbeta.com/20220228/107874726440746780.png')`}}></div>
            </div>
        );
    }
}
export default About;
